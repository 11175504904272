.trainer-certificates-edit-box {
  width: 100%;
}

.trainer-certificates-edit-box .tag,
.trainer-speciality-edit-box .tag {
  cursor: pointer;
}

.trainer-sessions-detail {
  padding: 20px;
}

.trainer-sessions-detail .session-item {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.certificates-list {
  border-top: 2px solid #f5f5f5;
  padding: 5px 0;
}

.certificates-list .certificate-tag-box {
}

.certificates-list .certificate-tag-box:hover {
  opacity: 0.75;
}

.certificates-list .certificate-tag span {
  display: inline-block;
}

.certificates-list .certificate-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.certificates-list .certificate-tag .tag-certificate {
  transition: all 0.25s;
}

.certificates-list
  .certificate-tag-box:hover
  .certificate-tag
  .tag-certificate {
  color: #f0f5ff;
}

.certificates-list .certificate-tag .tag-delete {
  position: absolute;
  width: 100%;
  color: #000000;
  font-weight: bolder;
  font-size: 12px;
  opacity: 0;
  transition: all 0.25s;
}

.certificates-list .certificate-tag-box:hover .certificate-tag .tag-delete {
  opacity: 0.85;
}

span.certificates-message {
  display: block;
  padding: 10px 30px;
  font-weight: 500;
  opacity: 0.5;
}
span.certificates-message.certificates-message-center {
  padding: 0;
  text-align: center;
}
