.product-image {
  width: 150px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
}

.product-input {
  width: 100%;
}

.show-product-image {
  width: 250px;
  border-radius: 10px;
}
