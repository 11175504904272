.training-form-box {
}

.training-form-box .form-title {
  text-align: center;
  padding: 10px 0;
}

.training-form-box .form-col {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.trainer-click {
  cursor: pointer;
}

.trainer-click .trainer-click-arrow {
  transition: 0.25s;
}

.trainer-click:hover {
  opacity: 0.8;
}

.trainer-click:hover .trainer-click-arrow {
  margin-left: 5px;
}

.training-show-item {
  padding: 20px 0;
  margin: 0 20px;
}

.show-item-text {
  padding: 10px;
}

.training-description {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.number-input {
  width: 100%;
}
