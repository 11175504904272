.show-item {
  padding: 20px 0;
  border-bottom: 1px solid #f5f5f5;
}

.show-profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.show-profile .profile-photo .avatar {
  width: 200px;
  max-height: 400px;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 5px #ccc;
}

.profile-photo .avatar img {
  width: 100%;
}

.profile-info-item {
  display: block;
  padding: 10px 5px;
  margin: 5px 0;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 1px 3px #ccc;
  font-weight: 600;
}

.profile-info-item.approved-item {
  background-color: #ffffff;
  box-shadow: none;
}

.profile-info-item span {
  margin: 5px;
}

.user-list-box .user-list-info .user-list-info-card {
  box-shadow: 0 1px 2px #ccc;
  border-radius: 10px;
}

.user-list-statics {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px #ccc;
  padding: 10px 0;
}

.user-list-sessions {
  box-shadow: 0 1px 3px #ccc;
  background-color: #fff;
  padding: 10px 0;
  border-radius: 10px;
}


.session-item span {
  font-size: 19px;
}

.session-item-small{
  height: 160px;
}