.session-item-row {
  margin: 12px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
}

.session-item-row .progress-box {
  text-align: center;
  padding: 20px;
}

.session-item-row .progress-box .ant-statistic-title {
  font-size: 20px;
}

.session-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
  margin: 8px 4px;
}

.session-item.dashboard {
  height: 220px;
}

.session-item.dashboard span {
  font-size: 20px;
}

.session-item.dashboard .ant-statistic-title {
  font-size: 20px;
}