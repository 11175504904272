.category-box .category-card-box {
  box-shadow: 0 2px 5px #ddd;
  margin: 10px;
  border-radius: 10px;
}

.category-box .category-card {
  text-align: center;
}

.category-box .action-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.category-box .category-actions,
.sub-category-item-box .category-actions {
  border: none;
}

.sub-category-item-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.sub-category-item-box .sub-category-item-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subcategory-create-box {
  padding: 20px;
  text-align: center;
}

.subcategory-create-box .sub-category-create-title {
  text-align: center;
}

.subcategory-create-box .sub-category-create-button {
  display: block;
  margin: 0 auto;
}

.subcategory-create-box .sub-category-create-input {
  max-width: 400px;
  width: 100%;
}

.sub-category-edit-button {
  display: block;
  margin-left: auto;
}