.item_person-list {
  cursor: pointer;
}

.person-session-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
