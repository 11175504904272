.ant-breadcrumb-link span {
  margin-right: 10px;
}

.tag {
  display: inline-block;
  margin: 5px;
  user-select: none;
  white-space: normal;
}


.tag-2x {
  padding: 3px 6px;
}

.tag-pointer {
  cursor: pointer;
}

.hr {
  opacity: .15;
}