.order-info-item {
  padding: 10px 0;
  display: block;
}

.order-info-item span.order-info-item_title {
  font-weight: 600;
  padding-right: 10px;
}

.order-detail {
  padding: 10px 20px;
}

p.order-info-item_text {
  padding: 5px 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 12px;
}

p.order-info-item_status {
  text-align: center;
}
