.customer-sessions-detail {
  padding: 20px;
}

.customer-sessions-detail .session-item {
  width: 110%;
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

.favorite-session-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
